// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Tabs from '../../ui-elements/Tabs/Tabs';
import News from './News/News';
// Service imports:
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Frettir: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    // TODO: Implement later. Doesn't show tab when user is already on page 1 but on other tab.
    // const [ tabToHasTwinkleMap, setTabToHasTwinkleMap ] = useState({
    //     'vidskipti': false,
    //     'almennar': false,
    //     'ithrottir': false,
    //     'skop': false,
    //     'hladvarp': false
    // });

    const defaultProps = {
        accessToken,
        refreshRateMs: CUSTOM_REFRESH_RATES['FRETTIR']
    };

    return (
        <DisplayBox
            title="Fréttir"
            className='KCL_frettir'
            anchorProps={{ href: '/Frettir' }}
            displayBoxSize="sm"
            {...props}
        >
            <Tabs tabs={[{
                id:  `${props.id}-vidskipti`,
                label: "Viðskipti",
                component: <News
                    {...defaultProps}
                    // setHasNew={(hasNewNewsItems: boolean) => setTabToHasTwinkleMap({
                    //     ...tabToHasTwinkleMap,
                    //     'vidskipti': hasNewNewsItems
                    // })}
                    newsFeedCategoryDetails={{
                        domesticOnly: "false",
                        foreignOnly: "true",
                        category: "is_bus",
                        domesticUsage: "true"
                    }}
                />,
                // hasTwinkle: tabToHasTwinkleMap['vidskipti']
            }, {
                id: `${props.id}-almennar`,
                label: "Almennar",
                component: <News
                    {...defaultProps}
                    // setHasNew={(hasNewNewsItems: boolean) => setTabToHasTwinkleMap({
                    //     ...tabToHasTwinkleMap,
                    //     'almennar': hasNewNewsItems
                    // })}
                    newsFeedCategoryDetails={{
                        domesticOnly: "true",
                        foreignOnly: "false",
                        category: "is_gen",
                        domesticUsage: "true"
                    }}
                />,
                // hasTwinkle: tabToHasTwinkleMap['almennar']
            }, {
                id: `${props.id}-ithrottir`,
                label: "Íþróttir",
                component: <News
                    {...defaultProps}
                    // setHasNew={(hasNewNewsItems: boolean) => setTabToHasTwinkleMap({
                    //     ...tabToHasTwinkleMap,
                    //     'ithrottir': hasNewNewsItems
                    // })}
                    newsFeedCategoryDetails={{
                        domesticOnly: "false",
                        foreignOnly: "true",
                        category: "is_spo",
                        domesticUsage: "true"
                    }}
                />,
                // hasTwinkle: tabToHasTwinkleMap['ithrottir']
            }, {
                id: `${props.id}-sjavarutvegur`,
                label: "Sjávarútvegur",
                component: <News
                    {...defaultProps}
                    // setHasNew={(hasNewNewsItems: boolean) => setTabToHasTwinkleMap({
                    //     ...tabToHasTwinkleMap,
                    //     'skop': hasNewNewsItems
                    // })}
                    newsFeedCategoryDetails={{
                        domesticOnly: "false",
                        foreignOnly: "true",
                        category: "is_fish",
                        domesticUsage: "true"
                    }}
                />,
                // hasTwinkle: tabToHasTwinkleMap['skop']
            }]}/>
        </DisplayBox>
    );
}

export default Frettir;