// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Flag, { FlagNames } from '../../../ui-elements/Flag/Flag';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import IntradayChart from '../IntradayChart/IntradayChart';
// Service imports:
import { formatNumber, getAPItoday } from '../../../services/utils';
import { currencyFractionDigits } from '../../../services/config';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import {  IApiLmdCurrency } from '../../../types/CurrencyTypes';

export type CurrenciesChartType = 'KIB' | 'USD' | 'EUR' | 'GBP' | 'CAD' | 'JPY' | 'CHF' | 'DKK' | 'SEK' | 'NOK' | 'PLN' | 'XDR';

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null
}

interface IOwnProps {
    currencySymbol: CurrenciesChartType
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const CurrencyIntradayChart: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    currencySymbol,
    ...props
}) => {
    const TODAY = getAPItoday();
    const [{data, error}] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/currency_cross/base_currency/ISK/cross_currency/${currencySymbol}/intraday_timeseries?from_date=${TODAY}&to_date=${TODAY}`,
        accessToken,
        refreshRateMs
    );

    const { AverageRate, ChangePer } = useMemo(() => {
        const filtered = data?.filter(
            datum => datum.Datetimerate !== null
                && Date.parse(datum.Datetimerate.replace(/ /g, "T")) <= Date.now()
        );
        return {
            AverageRate: filtered ? filtered[filtered.length-1]?.Averagerate : null,
            ChangePer: filtered ? filtered[filtered.length-1]?.ChangePer : null
        };
    }, [data]);

    if (error instanceof Error) {
        return (
            <DisplayBox
                className='KCL_currency-intraday-chart'
                {...props}
            > 
                <ChartHeader currencySymbol={currencySymbol} averageRate={AverageRate === null ? undefined : AverageRate} changePer={null} />
                <Alert type='error' headText={error.message}/>
            </DisplayBox>
        )
    } else if (data === null) {
        return (
            <DisplayBox
                className='KCL_currency-intraday-chart'
                {...props}
            > 
                <ChartHeader currencySymbol={currencySymbol} averageRate={AverageRate === null ? undefined : AverageRate} changePer={ChangePer} />
                <Loading />
            </DisplayBox>
        );
    }

    return (
        <DisplayBox 
            className='KCL_currency-intraday-chart'
            {...props}
            style={{paddingBottom: '10px', minHeight: '400px'}}
        >
            <ChartHeader
                currencySymbol={currencySymbol}
                averageRate={AverageRate ?? 0}
                changePer={ChangePer ?? 0}
                />
            <IntradayChart
                currency={currencySymbol}
                data={data}
            />
        </DisplayBox>
    );
}

interface IChartHeaderProps{
    currencySymbol: CurrenciesChartType,
    averageRate?: number,
    changePer: number | null
}
const ChartHeader:React.FC<IChartHeaderProps> = ({currencySymbol, averageRate, changePer}) => {
    interface INameOfCurrency {
        [key: string]: string
    }
    const nameOfCurrency: INameOfCurrency = {
        USD: 'Bandaríkjadalur',
        EUR: 'Evra',
        GBP: 'Sterlingspund',
        CAD: 'Kanadískur dalur',
        JPY: 'Japanskt jen',
        CHF: 'Svissneskur franki',
        DKK: 'Dönsk króna',
        SEK: 'Sænsk króna',
        NOK: 'Norsk króna',
        PLN: 'Pólskt slot'
    }
    return <div>
        <div className='intraday-chart__head'>
            <Flag name={currencySymbol as FlagNames} />
            <h4>
                <span className='currency-symbol'>{currencySymbol}</span>
                {
                    averageRate !== undefined && 
                        <span className='currency-averageRate'>
                            {formatNumber(averageRate, '-', currencyFractionDigits(currencySymbol))}
                        </span>
                }
            </h4>
            { changePer !== null && <span className='currency-changePer'>
                <CalculatedColoredNumber
                    currentValue={changePer}
                    formatting='percent'
                    hasArrow
                    hasColor
                />
            </span>
            }
        </div>
        <p style={{paddingLeft: '36px', marginBottom: '20px'}} className='italic-text'>{nameOfCurrency[currencySymbol]}</p>
    </div>
}
export default CurrencyIntradayChart;