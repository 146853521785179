// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
// Service imports:
import { formatNumber } from '../../../services/utils';
// Type imports:
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';
import { currencyFractionDigits } from '../../../services/config';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};

interface IProps {
    data: IApiLmdCurrency[],
    currency: string,
    detailed?: boolean,
    height?: number
};

const IntradayChart: React.FC<IProps> = ({
    data,
    currency,
    detailed = false,
    height = 289
}) => {
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const chartDataInfo: IChartDataInfo = {
            name: '',
            data: []
        }
        // Function to get milliseconds since midnight for a given hour and minute
        const getMillisecondsSinceMidnight = (hours: number, minutes: number): number => {
            const now = new Date();
            return new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                hours,
                minutes
            ).getTime();
        };
        //we want to show the chart from 8:55 to 17:00
        const marketOpen = getMillisecondsSinceMidnight(8, 55);
        const marketClose = getMillisecondsSinceMidnight(17, 0);
        for (let datum of data) {
            if (datum.Datetimerate && Date.parse(datum.Datetimerate.replace(/ /g, "T")) <= Date.now()) {
                chartDataInfo.data.push({
                    x: datum.Datetimerate ? Date.parse(datum.Datetimerate.replace(/ /g, "T")) : undefined,
                    y: datum.Averagerate ?? undefined
                });
            }
        }

        Highcharts.setOptions({
            lang: {
                resetZoom: 'Til baka',
                resetZoomTitle: ''
            }
        });
        const max = Math.max(...chartDataInfo.data.map(point => point.y ?? 0));
        const min = Math.min(...chartDataInfo.data.map(point => point.y ?? 0));
        const padding = Math.pow(10, -currencyFractionDigits(currency));

        if (divRef.current !== null) {
            Highcharts.chart(divRef.current, {
                chart: {
                    height: height,
                    marginTop: detailed ? 80 : 10,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    zoomType: detailed ? "xy" : undefined,
                    selectionMarkerFill: '#4569EE18',
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -75
                        },
                        theme: {
                            r: 6,
                            height: 28
                        }
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {
                        return `
                            <div class="chart-tooltip">
                                <p class="tooltip-date">${'Klukkan: <strong>' + ((new Date(this.x).getHours()).toString()).padStart(2, '0') + ':' + ((new Date(this.x).getMinutes()).toString()).padStart(2, '0') }</strong></p>
                                <p class="tooltip-value">${'Verð: <strong>'} ${formatNumber(this.y, null,  currencyFractionDigits(currency))}</strong></p>
                            </div>`;
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: ''
                    },
                    minTickInterval: detailed ? 1 : 10,
                    tickWidth: 0,
                    min: marketOpen,
                    max: marketClose,
                    labels: {
                        format: '{value:%H:%M}'
                    }
                }],
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    tickAmount: 5,  // Show  5 tick marks
                    labels: {
                        enabled: true,
                        align: 'right',
                        x: -10,
                        formatter: function() {
                            return formatNumber(Number(this.value), null, currencyFractionDigits(currency)) ?? '';
                        }
                    },
                    startOnTick: false,
                    endOnTick: false,
                    showFirstLabel: true,
                    showLastLabel: true,
                        // Calculate min and max with padding
                        min: min - padding,
                        max: max + padding
                },
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: true,
                        marker: {
                            enabled: false
                        },
                        animation: true,
                        turboThreshold: 0,
                    }
                },
                legend: {
                    enabled: false,
                    align: 'left',
                    verticalAlign: 'top',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    y: 10
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                            }
                        }
                    }]
                },
                series: [{
                    type: 'line',
                    data: chartDataInfo.data,
                }]
            }, () => {});
        }
    }, [data, divRef.current]);

    return (
        <div className="KCL_intraday-chart">
            <div className="chart" ref={divRef}></div>
        </div>
    );
}

export default IntradayChart;