// Package imports:
import React from 'react';
// Component imports:
import NewsAnnouncementsPage from '../../components/NewsAnnouncementsPage/NewsAnnouncementsPage';
import Entrypoint from '../Entrypoint';

const Announcements: React.FC = () => {
    return (
        <Entrypoint>
            <NewsAnnouncementsPage />
        </Entrypoint>
    );
}

export default Announcements;