// Package imports:
import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import HeatMap from '../HeatMap/BondsHeatMap';
import MarketOverview from '../MarketOverview/MarketOverview';
import AdRotator from '../Ad/AdRotator';
import Tabs from '../../ui-elements/Tabs/Tabs';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import YieldCurve from '../Charts/YieldCurve/YieldCurve';
import BondsTable from '../BondsTable/BondsTable';
import OpeningHours from '../OpeningHours/OpeningHours';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const queries = [
    {
        columns: 2,
        query: 'min-width: 1024px'
    },
];

const BondsPage: React.FC = () => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className='main KCL_bonds-page KCL_market-page'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        hasAds='market'
                        title='Skuldabréf'
                        summary={
                            <p>Skuldabréfaupplýsingar frá Kauphöll Íslands eru birtar með 15 mínútna seinkun. Rauntímaupplýsingar eru í boði í gegnum Keldan markaðir, KODIAK Pro og KODIAK Excel.</p>
                        }
                        actions={<>
                            <Link
                                url="/Keldan-Markadir"
                                linkSize='16'
                                icon='forward'
                            >
                                Nánar um Keldan markaðir
                            </Link>
                            <Link
                                url="https://www.kodi.is/product/kodiak-pro-2/"
                                linkSize='16'
                                targetBlank
                                icon='forward'
                            >
                                Nánar um KODIAK Pro
                            </Link>
                            <Link
                                url="https://www.kodi.is/product/kodiak-excel/"
                                linkSize='16'
                                targetBlank
                                icon='forward'
                            >
                                Nánar um KODIAK Excel
                            </Link>
                        </>}
                        rightSide={<OpeningHours type="kaupholl" />}
                    />
                    <div className="main__cols grid-items">
                        <Columns className="custom-cols" queries={queries}>
                            <div className="grid-item">
                                <MarketOverview
                                    overviewType='bonds'
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['MARKETOVERVIEW']}
                                    id="BondsPage_MarketOverview"
                                />
                            </div>
                            <div className="grid-item">
                                <HeatMap
                                    accessToken={accessToken}
                                    refreshRateMs={MARKET_REFRESH_RATES['HEATMAP']}
                                    id="BondsPage_HeatMap"
                                />
                            </div>
                            <div className="grid-item">
                                <DisplayBox
                                    title="Vaxtaferlar"
                                    id="BondsPage_YieldCurves"
                                >
                                    <Tabs
                                        tabs={[{
                                            id: 'indexed',
                                            label: 'Verðtryggð',
                                            component: <YieldCurve
                                                indexed={true}
                                                accessToken={accessToken}
                                                refreshRateMs={MARKET_REFRESH_RATES['YIELDCURVE']}
                                            />
                                        }, {
                                            id: 'nonindexed',
                                            label: 'Óverðtryggð',
                                            component: <YieldCurve
                                                indexed={false}
                                                accessToken={accessToken}
                                                refreshRateMs={MARKET_REFRESH_RATES['YIELDCURVE']}
                                            />
                                        }]}
                                    />
                                </DisplayBox>
                            </div>
                        </Columns>
                    </div>
                    <div className="grid-items">
                        <div className='grid-item'>
                            <AdRotator location="Market1018x360" />
                        </div>
                    </div>
                    <div className="grid-items">
                        <div className='grid-item'>
                            <BondsTable
                                indexed={true}
                                showDatepicker
                                accessToken={accessToken}
                                refreshRateMs={MARKET_REFRESH_RATES['BONDSTABLE']}
                                id="BondsPage_BondsTableIndexed"
                            />
                        </div>
                        <div className='grid-item'>
                            <BondsTable
                                indexed={false}
                                showDatepicker
                                accessToken={accessToken}
                                refreshRateMs={MARKET_REFRESH_RATES['BONDSTABLE']}
                                id="BondsPage_BondsTableNonIndexed"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BondsPage;