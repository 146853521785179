// Package imports:
import React, { useContext, useEffect, useState } from 'react';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import Alert from '../../ui-elements/Alert/Alert';
import FrettavaktContainer from './FrettavaktContainer/FrettavaktContainer';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import NewsWatcher from '../Frettir/News/NewsWatcher';
// Type imports:
import { IDefaultProps, IisAuthenticated } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const Frettavakt: React.FC<IisAuthenticated> = ({
    isAuthenticated,
    isSubscriber
}) => {
    const accessToken =  useContext(AccessTokenContext);
    const [defaultProps, setDefaultProps] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: 60 * 1000
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className="main KCL_frettavakt">
            <div className="shell">
                <HeadSection
                    title={<>
                        <h1>Fréttavakt Keldunnar</h1>
                        {(isAuthenticated && isSubscriber)
                            ? <Button
                                smallInMobile
                                anchorProps={{href: "/Min-Kelda/Voktun#FrettaVaktin"}}
                                buttonType='secondary'
                                icon={faMegaphone}
                            >
                                Mín fréttavakt
                            </Button>
                            : null
                        }
                    </>}
                    hasAds='main'
                />
                <div className='newsWatcher_wrapper'>
                    <div className='description_newsWatcher'>
                        <p className='summary-paragraph'>
                            Vaktaðu fréttir með ákveðnum orðum eða orðasamböndum og fáðu tilkynningu um leið og þau birtast í fréttum.
                        </p>
                        <NewsWatcher isAuthenticated={isAuthenticated} isSubscriber={isSubscriber}/>
                    </div>
                    <Alert
                        type={"info"}
                        headText={"Athugið"}
                        text={<div>
                                 <p>
                                    Komma (,) á milli leitarorða skilar fréttum sem innihalda bæði orð eða orðasambönd.
                                    Gæsalappir (“...”) utanum orðasambönd skila fréttum sem innihalda orð í þeirri röð.
                                    Mínus merki (-) fyrir framan orð skila fréttum sem innihalda ekki orðið.
                                    <br/><strong>Fréttavaktin nær til fyrirsagna og textabúta, eða eins og þær birtast á Keldunni.</strong>
                                    </p>
                            </div>
                        }
                    />
                </div>
                <Tabs
                    tabsSize='lg'
                    tabs={[{
                        id: "vidskipti",
                        label: "Viðskipti",
                        component: <FrettavaktContainer
                            {...defaultProps}
                            newsFeedCategoryDetails={{
                                domesticOnly: "false",
                                foreignOnly: "true",
                                category: "is_bus",
                                domesticUsage: "true"
                            }}
                        />,
                    }, {
                        id: "almennar",
                        label: "Almennar",
                        component: <FrettavaktContainer
                            {...defaultProps}
                            newsFeedCategoryDetails={{
                                domesticOnly: "true",
                                foreignOnly: "false",
                                category: "is_gen",
                                domesticUsage: "true"
                            }}
                        />,
                    }, {
                        id: "ithrottir",
                        label: "Íþróttir",
                        component: <FrettavaktContainer
                            {...defaultProps}
                            newsFeedCategoryDetails={{
                                domesticOnly: "false",
                                foreignOnly: "true",
                                category: "is_spo",
                                domesticUsage: "true"
                            }}
                        />,
                    }, {
                        id: "sjavarutvegur",
                        label: "Sjávarútvegur",
                        component: <FrettavaktContainer
                            {...defaultProps}
                            newsFeedCategoryDetails={{
                                domesticOnly: "false",
                                foreignOnly: "false",
                                category: "is_fish",
                                domesticUsage: "true"
                            }}
                        />,
                    }]}
                />
            </div>
        </div>
    )
}

export default Frettavakt;