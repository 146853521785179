// Package imports:
import React, { useState } from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Button from '../../ui-elements/Button/Button';
import FullScreenImage from '../../ui-elements/FullScreenImage/FullScreenImage';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import PriceCard from '../PriceCard/PriceCard';
import TilkynningarContactForm from '../ContactForms/TilkynningarContactForm/TilkynningarContactForm';
import Label from '../../ui-elements/Label/Label';
import Link from '../../ui-elements/Link/Link';

const AboutNewswire: React.FC = () => {
    const [ fullScreenImageUrl, setFullScreenImageUrl ] = useState<string | null>(null);

    return (
        <div className='main KCL_about-newswire'>
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Tilkynningar screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className='shell'>
                <HeadSection
                    title={
                        <object
                            data="https://cdn.livemarketdata.com/KeldanImages/Keldan_tilkynningar_logo.svg"
                            aria-label="Keldan | Tilkynningar"
                        >
                            Keldan | Tilkynningar
                        </object>
                    }
                    summary={
                        <p>Skilvirk leið til að miðla upplýsingum beint til fjárfesta, greiningaraðila, fjölmiðla og annarra hagsmunaaðila. Tilkynningakerfi Keldunnar er fyrir fyrirtæki, stofnanir og aðra sem þurfa að koma fréttum og fréttatilkynningum á framfæri.</p>
                    }
                    actions={
                        <>
                            <Button
                                buttonType='secondary'
                                size='lg'
                                anchorProps={{
                                    href: 'https://newswire.keldan.is/Innskraning',
                                    target: '_blank'
                                }}
                            >
                                Innskráning
                            </Button>
                            <Button
                                buttonType='primary'
                                size='lg'
                                onClick={() =>
                                    document
                                        .getElementById("Askrift")
                                        ?.scrollIntoView({ behavior: "smooth" })
                                }
                            >
                                Áskrift
                            </Button>
                        </>
                    }
                    textAlign='center'
                />
            </div>
            <div className='section__body'>
                <div className='shell'>
                    <TextImageBlock
                        imgSrc="https://cdn.livemarketdata.com/KeldanImages/KeldanTilkynningar1.png"
                        imgSide="right"
                    >
                        <h2 id="Tilkynningar_RSS">RSS fréttasöfnun og birting</h2>
                        <div className='text-image-block__entry'>
                            <p className='paragraph'>
                                Keldan setur upp RSS fréttasafnara fyrir áskrifendur að tilkynningaþjónustu Keldunnar. Safnarinn sækir almennar fréttatilkynningar beint af vefsíðum fyrirtækja og birtir þær á Keldunni. Áskrifendur fá einnig aðgang að tilkynningakerfinu þar sem þeir geta séð upplýsingar um söfnun og birtingu. Í tilkynningakerfinu geta áskrifendur einnig tekið fréttir, sem hafa farið á Kelduna í gegnum RSS straum, úr birtingu.
                            </p>
                            <br/>
                            <p className='paragraph'>
                                <span className='paragraph--bold'>Verð: 29.000 kr. á mánuði án vsk.</span><br/>
                                Innifalinn er aðgangur fyrir tvo notendur að tilkynningakerfi Keldunnar.
                            </p>
                            <Link linkSize='18' href='/Tilkynningar-Skilmalar' icon='forward'>Lesa skilmála</Link>
                        </div>
                    </TextImageBlock>
                    <TextImageBlock
                        imgSrc="https://cdn.livemarketdata.com/KeldanImages/KeldanTilkynningar2.png"
                        imgSide="left"
                    >
                        <Label labelType='new' text='Væntanlegt' />
                        <h2 id='Tilkynningar_Distribution'>Frumbirting fréttatilkynninga</h2>
                        <div className='text-image-block__entry'>
                            <p className='paragraph'>
                                Með tilkynningakerfi Keldunnar geta sérfræðingar í samskiptum skrifað fréttatilkynningar og valið um dreifileiðir auk þess að birta á forsíðu Keldunnar.
                            </p>
                            <br/>
                            <h6>Verð kynnt síðar.</h6>
                        </div>
                    </TextImageBlock>
                    <TextImageBlock
                        imgSrc="https://cdn.livemarketdata.com/KeldanImages/Tilkynningar1.png"
                        imgSide='right'
                    >
                        <Label labelType='new' text='Væntanlegt' />
                        <h2 id="Tilkynningar_Exchange">Kauphallartilkynningar</h2>
                        <div className='text-image-block__entry'>
                            <p className='paragraph'>
                                Útgefendur skráðra verðbréfa geta fljótlega notað tilkynningakerfi Keldunnar til þess að senda kauphallartilkynningar. Kerfið mun uppfylla þær kröfur sem NASDAQ Iceland og Fjármálaeftirlit Seðlabankans gera til birtingar á tilkynningum sem í daglegu tali eru kallaðar kauphallartilkynningar.
                            </p>
                            <br/>
                            <h6>Verð kynnt síðar.</h6>
                        </div>
                    </TextImageBlock>
                </div>
            </div>
            <div className="section section--gray section--contact" id="Askrift">
                <div className='shell'>
                    <div className='section__inner'>
                        <div className='section__head'>
                            <h2 id="Tilkynningar_Subscribe">Áskrift að Keldan tilkynningar</h2>
                            <p className='summary-paragraph'>
                                Hafðu samband til að koma í áskrift, en einnig getur þú sent fyrirspurn hafir þú einhverjar spurningar.
                            </p>
                            <TilkynningarContactForm />
                        </div>
                        <div className='section__body'>
                            <PriceCard
                                variant='overview'
                                type='Áskrift'
                            >
                                <p className='paragraph--bold'>Keldan tilkynningar</p>
                                <div className="price-card__details">
                                    <dl>
                                        <dt>RSS fréttasöfnun og birting</dt>
                                        <dd>29.000 kr.</dd>
                                    </dl>
                                    <dl>
                                        <dt>Frumbirting fréttatilkynninga</dt>
                                        <dd><em>Kemur síðar</em></dd>
                                    </dl>
                                    <dl>
                                        <dt>Kauphallartilkynningar</dt>
                                        <dd><em>Kemur síðar</em></dd>
                                    </dl>
                                    <p className='paragraph-small--italic'>Athugið, verðin eru án VSK.</p>
                                </div>
                            </PriceCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutNewswire;