// Package imports:
import React from 'react';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import ScrollSpy from '../../ui-elements/ScrollSpy/ScrollSpy';
import ScrollSpySection from '../../ui-elements/ScrollSpy/ScrollSpySection';
import BulletList from '../../ui-elements/Lists/BulletList';
import Link from '../../ui-elements/Link/Link';

const Component: React.FC = () => {
    return (
        <div className='main KCL_disclaimer'>
            <div className='mini_shell'>
                <HeadSection
                    title='Skilmálar fyrir söfnun og birtingu RSS frétta á Keldunni'
                    summary={
                        <p>
                            Með því að nota RSS strauma Keldunnar samþykkir þú eftirfarandi skilmála. Ef þú hefur spurningar eða þarft frekari upplýsingar, vinsamlegast hafðu samband við okkur í gegnum <Link href='mailto:info@keldan.is' linkSize='20'>info@keldan.is</Link>.
                        </p>
                    }
                />
                <div className='section__body'>
                    <div className='keldanTilkynningar'>
                        <article className='article-overview'>
                            <div className='article__body'>
                                <ScrollSpy
                                    containerClass='article__content'
                                    navClass='article__nav'
                                    parentClass='keldanTilkynningar'
                                    initialStep='um-thjonustuna'
                                >
                                    <ScrollSpySection
                                        className='article__section'
                                        id='um-thjonustuna'
                                        label='Um þjónustuna'
                                    >
                                        <h2>Um þjónustuna</h2>
                                        <p>
                                            Keldan safnar fréttum frá áskrifendum í gegnum RSS strauma og birtir í þar til gerðum fréttahluta á vefsíðu Keldunnar.
                                        </p>
                                    </ScrollSpySection>
                                    <ScrollSpySection
                                        className='article__section'
                                        id='sofnun-og-birting'
                                        label='Söfnun og birting RSS frétta'
                                    >
                                        <h2>Söfnun og birting RSS frétta</h2>
                                        <BulletList
                                            items={[
                                                <p key='fagleg-notkun'><span className='paragraph--bold'>Fagleg notkun</span>: Áskrifendum er heimilt að nota RSS tengingu við Kelduna í faglegum tilgangi, og að því gefnu að öðrum skilmálum vefsíðunnar sé fylgt.</p>,
                                                <p key='videigandi-birting'><span className='paragraph--bold'>Viðeigandi birting</span>: Efni sem birt er úr RSS straumum þarf að vera viðeigandi fyrir lesendur Keldunnar og í samræmi við tilgang vefsíðunnar.</p>,
                                                <p key='fjoldi-greina'><span className='paragraph--bold'>Fjöldi greina</span>: Áskrifendur skulu gæta skynsemi í fjölda birtra greina til að tryggja jafnvægi og gæði efnis fyrir lesendur.</p>,
                                                <p key='rettmmaet-birting'><span className='paragraph--bold'>Réttmæt birting</span>: Tryggja skal að efni sem birt er í gegnum RSS strauma Keldunnar sé í samræmi við gildandi lög og reglur, og að það sé ekki meiðandi eða niðurlægjandi fyrir aðra. Þá skal gætt að efni og birting efnis skaði ekki orðspor Keldunnar, eða valdi öðrum notendum ekki tjóni.</p>
                                            ]}
                                        />
                                    </ScrollSpySection>
                                    <ScrollSpySection
                                        className='article__section'
                                        id='abyrgd-og-fyrirvari'
                                        label='Ábyrgð og fyrirvari'
                                    >
                                        <h2>Ábyrgð og fyrirvari</h2>
                                        <BulletList
                                            items={[
                                                <p key='areidanleiki-upplysinga'><span className='paragraph--bold'>Áreiðanleiki upplýsinga</span>: Tryggja skal áreiðanleika þeirra upplýsinga sem áskrifendur birta í gegnum RSS strauma á Keldunni. Áskrifendur einir bera ábyrgð á mögulegu tjóni sem kann að hljótast af notkun og birtingu efnis.</p>,
                                                <p key='taeknileg-vandamal'><span className='paragraph--bold'>Tæknileg vandamál</span>: Keldan ber ekki ábyrgð á tjóni sem kann að hljótast af tæknilegum vandamálum eða bilunum sem geta haft áhrif á aðgengi að RSS straumum eða tilkynningakerfinu.</p>,
                                                <p key='ovideigandi-efni'><span className='paragraph--bold'>Óviðeigandi efni</span>: Keldan áskilur sér rétt til að eyða óviðeigandi eða ófullnægjandi efni sem birtist í tengslum við RSS strauma. Sama gildir ef áskrifandi birtir óeðlilega margar greinar. Keldan áskilur sér þá rétt að fjarlægja þá áskrifendur og efni þeirra ef þeir verða uppvísir af brotum á skilmálum þessum, án fyrirvara, og þar til gerðar hafa verið viðeigandi úrbætur og ráðstafanir.</p>
                                            ]}
                                        />
                                    </ScrollSpySection>
                                    <ScrollSpySection
                                        className='article__section'
                                        id='breytingar-a-skilmalum'
                                        label='Breytingar á skilmálum'
                                    >
                                        <h2>Breytingar á skilmálum</h2>
                                        <BulletList
                                            items={[
                                                <p key='endurskodun'><span className='paragraph--bold'>Endurskoðun</span>: Keldan áskilur sér rétt til að breyta skilmálum þessum hvenær sem er. Breytingar taka gildi við birtingu á vefsíðu Keldunnar.</p>
                                            ]}
                                        />
                                    </ScrollSpySection>
                                </ScrollSpy>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Component;