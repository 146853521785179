import React, { useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
import cx from 'classnames';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Label from '../../../ui-elements/Label/Label';
import DefaultChart from '../DefaultChart/DefaultChart';
import { formatNumber } from '../../../services/utils';
import { IDefaultProps } from '../../../types/Types';

interface IOwnProps {
    symbol?: string;
    title?: string;
    height?: number;
    data: [] | IVisibleCurrency[];
}

interface IVisibleCurrency {
    symbol: string;
    name: string;
    color: string;
    data: number[][];
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const GjaldmidlarChart: React.FC<Props> = ({
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    data,
    className,
    ...props
}) => {
    const showPercentageChange = data.length > 1;
    const series = useMemo(() => {
        return data.map((currency) => ({
            id: currency.symbol,
            type: 'line' as const,
            name: currency.name,
            color: currency.color,
            data: currency.data,
        }));
    }, [data]);
    const getCurrencyCode = (symbol: string): string => {
        // Handle CBI prefix and extract the first three characters of the currency pair
        return symbol.replace('CBI', '').substring(0, 3);
    };
    const tooltipFormatter = function(this: Highcharts.TooltipFormatterContextObject) {
        const points = (this.points || []).filter(point => point.point.visible !== false);
        const dateStr = format(this.x as number, 'EEEE, dd. MMM yyyy', {locale: ISLocale});
        //@ts-ignore change does exist on type Point
        const pointsHtml = points.sort((a, b) => showPercentageChange ? b.point.change - a.point.change: b.y - a.y).map(point => {
            const currencyCode = getCurrencyCode(point.series.name);
            const flagUrl = `https://cdn.livemarketdata.com/Avatars/Flags/${currencyCode}.svg`;
            return `
            <div style="display: flex; align-items: center; margin: 5px 0;">
                <img 
                src="${flagUrl}"
                alt="${currencyCode}"
                style="width: 14px; height: 14px; margin-right: 4px;"
                />
                <span style="color: ${point.color}; margin-right: 8px;">
                ${point.series.name}
                </span>
                <span><strong>${showPercentageChange 
                    //@ts-ignore change does exist on type Point
                    ? formatNumber(point.point.change, '-', 2) + '%'
                    : formatNumber(point.y, '-', 3)
            }</strong></span>
            </div>`;
        })
        .join('');
        return `
        <div class="chart-tooltip">
            <p class="tooltip-date"><strong>${dateStr}</strong></p>
            ${pointsHtml}
        </div>`;
    };

    const yAxisTitle = useMemo(() => {
        return showPercentageChange ? 'Prósentubreyting' : 'Kross';
    }, [data.length]);

    return (
        <DisplayBox
            className={cx('KCL_chart', className)}
            title={title}
            footerRight={
                <Label
                    text="Seðlabanki Íslands"
                    labelType="origin"
                    url='https://sedlabanki.is'
                />
            }
            {...props}
        >
            <div className='row'>
                <DefaultChart
                    height={height}
                    tooltipFormatter={tooltipFormatter}
                    yAxes={[{
                        title: {
                            text: yAxisTitle,
                            margin: 15
                        },
                        height: '100%',
                        labels: {
                            formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                return showPercentageChange 
                                    ? `${formatNumber(Number(this.value), '-', 2)}%`
                                    : formatNumber(Number(this.value), '-', 3) ?? '';
                            }
                        }
                    }]}
                    series={series}
                    seriesOptions={{
                        compare: showPercentageChange ? 'percent' : undefined
                    }}
                />
            </div>
        </DisplayBox>
    );
};

export default GjaldmidlarChart;