// Package imports:
import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import CurrenciesSingleBase from '../CurrenciesSingleBase/CurrenciesSingleBase';
import CurrencyIntradayChart, { CurrenciesChartType } from '../IntradayCharts/CurrencyIntradayChart/CurrencyIntradayChart';
import AdRotator from '../Ad/AdRotator';
import Label from '../../ui-elements/Label/Label';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import GjaldmidlarChart from '../Charts/GjaldmidlarChart/GjaldmidlarChart';
// Service imports:
import { DEFAULT_REFRESH_RATE, MARKET_REFRESH_RATES } from '../../services/config';
import { useApiLmdDataMappedByString } from '../../services/apiHooks';
import { getAPItoday } from '../../services/utils';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCurrency } from '../../types/CurrencyTypes';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

interface IVisibleCurrency {
    symbol: string,
    name: string,
    color: string,
    data: number[][]
}

interface IProps {
    defaultSymbol?: string
}
interface ColorMap {
    [key: string]: string
}
const colors: ColorMap = {
    ISK: '#0097A7', //Teal Blue
    USD: '#ff7f0e', // Safety Orange
    EUR: '#4569EE', // Default Blue
    GBP: '#2ca02c', // Forest Green
    CAD: '#d62728', // Crimson
    JPY: '#9467bd', // Medium Purple
    CHF: '#8c564b', // Brown
    DKK: '#e377c2', // Orchid Pink
    SEK: '#7f7f7f', // Gray
    NOK: '#bcbd22', // Olive
    PLN: '#17becf'  // Cyan
};

const GjaldmidlarPage: React.FC<IProps> = ({
    defaultSymbol
}) => {
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    const queries = [{
        columns: 2,
        query: 'min-width: 1024px'
    }, {
        columns: 3,
        query: 'min-width: 1280px'
    }];

    const currencySymbols: CurrenciesChartType[] = ['USD', 'EUR', 'GBP', 'CAD', 'JPY', 'CHF', 'DKK', 'SEK', 'NOK', 'PLN'];
   
    const queriesForUpper = [{
        columns: 2,
        query: 'min-width: 1024px'
    }];
    // List of funds that are visible in the chart.
    const [visibleChartCurrencies, setVisibleChartCurrencies] = useState<IVisibleCurrency[]>([{
        symbol: defaultSymbol ? defaultSymbol : 'CBIISK',
        name: defaultSymbol ? defaultSymbol : 'CBIISK',
        color: colors[defaultSymbol ? defaultSymbol.substring(3, 6) : 'ISK'],
        data: []
    }]);

    const today = new Date();
    const from = new Date(today.getFullYear() - 5, today.getMonth() + 1, today.getDate());
    // Hook used for fetching chart data for clicked symbol.
    const [symbolToFetchFor, setSymbolToFetchFor, newDataForFetchedSymbol] = useApiLmdDataMappedByString<IApiLmdCurrency[]>(
        visibleChartCurrencies[0]?.symbol ?? '',
        (symbol: string) => `/v1/market_data/v1/currency_cross/base_currency/${symbol.substring(0, 3).toUpperCase()}/cross_currency/${symbol.substring(3, 6).toUpperCase()}/history/?from_date=${getAPItoday(from)}&to_date=${getAPItoday(today)}`,
        accessToken,
        MARKET_REFRESH_RATES['CURRENCYCHART']
    );

    const handleCurrencyClick = (symbol: string | null) => {
        if (symbol === null) return;
        if (newDataForFetchedSymbol.data === null && newDataForFetchedSymbol.error === null) return;
        
        const existingChartCurrencies = visibleChartCurrencies.find(chartCurrency => chartCurrency.symbol === symbol);
        
        if (existingChartCurrencies === undefined) {
            // Adding a new currency
            if (symbol === symbolToFetchFor) {
                setVisibleChartCurrencies(visibleChartCurrencies.concat([{
                    symbol,
                    name: symbol,
                    data: newDataForFetchedSymbol.data?.map((item) => [Date.parse(item.Datetimerate ?? ''), item.Averagerate ?? 0]) ?? [],
                    color: `${colors[symbol.substring(3, 6)]}`
                }]))
            } else {
                setVisibleChartCurrencies(visibleChartCurrencies.concat([{
                    symbol,
                    name: 'Hleður...',
                    color: `${colors[symbol.substring(3, 6)]}`,
                    data: []
                }]));
                setSymbolToFetchFor(symbol);
            }
        } else {
            // Only allow removal if there's more than one currency visible
            if (visibleChartCurrencies.length > 1) {
                setVisibleChartCurrencies(visibleChartCurrencies.filter(chartCurrency => chartCurrency.symbol !== symbol));
            }
        }
    }

    useEffect(() => {
        const existingVisibleChartCurrenciesIndex = visibleChartCurrencies.findIndex(visibleChartCurrencies => visibleChartCurrencies.symbol === symbolToFetchFor);
        if (existingVisibleChartCurrenciesIndex !== -1 && newDataForFetchedSymbol.data !== null) {
            const existingVisibleChartCurrencies = visibleChartCurrencies[existingVisibleChartCurrenciesIndex];
            const newVisibleChartCurrenciess = [...visibleChartCurrencies];
            newVisibleChartCurrenciess.splice(existingVisibleChartCurrenciesIndex, 1, {
                ...existingVisibleChartCurrencies,
                name: symbolToFetchFor,
                data: newDataForFetchedSymbol.data.map((item) => [Date.parse(item.Datetimerate ?? ''), item.Averagerate ?? 0])
            })
            setVisibleChartCurrencies(newVisibleChartCurrenciess);
        }
    }, [symbolToFetchFor, newDataForFetchedSymbol]);
    
    const DATATOSHOW = currencySymbols.map((symbol) => (
        <div key={symbol} className="grid-item">
            <CurrencyIntradayChart
                currencySymbol={symbol}
                accessToken={accessToken}
                refreshRateMs={MARKET_REFRESH_RATES['INTRADAYCHART']}
                id={`CurrenciesPage_IntradayChart${symbol}`}
            />
        </div>
    ));

    // Insert advertisement at index 5
    DATATOSHOW.splice(5, 0, 
        <div key="auglysing" className="grid-item">
            <AdRotator location="Market310x400" />
        </div>
    );


    return (
        <div className='main KCL_gjaldmidlar-page KCL_market-page'>
            <div className="main__inner-fluid">
                <div className="shell">
                    <HeadSection 
                        hasAds='market'
                        title='Gjaldmiðlar'
                    />
                    <div>
                        <div className='main__cols grid-items' >
                            <Columns className="custom-cols" queries={queriesForUpper}>
                                <div className="grid-item">
                                    <CurrenciesSingleBase
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['CURRENCIESSINGLEBASE']}
                                        id="CurrenciesPage_CurrenciesSingleBase"
                                        onSymbolClick={handleCurrencyClick}
                                    />
                                </div>
                                <div className="grid-item" id="sogulegt-gengi">
                                    <GjaldmidlarChart
                                        title="Sögulegt gengi"
                                        accessToken={accessToken}
                                        refreshRateMs={MARKET_REFRESH_RATES['CURRENCYCHART']}
                                        id="CurrenciesPage_HistoryChart"
                                        data={visibleChartCurrencies}
                                        className='chart--half-width'
                                    />
                                </div>
                            </Columns>
                        </div>
                        <AdRotator location="Market1018x360" />
                        <div className='section-before-charts'>
                            <h2 id="Currencies_IntradayRates">Stundargengi innan dags</h2>
                            <Label
                                text="Landsbankinn"
                                labelType="origin"
                                url='https://landsbankinn.is'
                            />
                        </div>
                        <div className='main__cols grid-items'>
                            <Columns className="custom-cols" queries={queries}>
                                {DATATOSHOW}
                            </Columns>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GjaldmidlarPage;
